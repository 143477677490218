import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
{/*import {VscFolderLibrary} from 'react-icons/vsc'*/}

const about = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
      </div>

      <div className="about__content">
        <div className="about__cards">

          <article className='about__card'>
            <FaAward className='about__icon'/>
            <h5>Cybersecurity Experience</h5>
            <small>5 years</small>
          </article>

          <article className='about__card'>
            <FiUsers className='about__icon'/>
            <h5>Front End Developer</h5>
            <small>Less than 1 year</small>
          </article>

           {/*Third block. Not sure what to add */}
          {/*<article className='about__card'>
            <VscFolderLibrary className='about__icon'/>
            <h5>Ideas</h5>
            <small>Not sure what to add. I couldnt think of it</small>
          </article>*/}

          </div>

          <p>
          Hello World!<br></br>

First and foremost, I love playing on the computer. Ever since I was young, it’s what I enjoyed doing. Now that I’m an adult, I’m a Senior Information Security Engineer who gets to tinker on many projects all day long. The Security space is filled with new threats daily. I enjoy learning about all the ways hackers infiltrate and own entire networks. All while tinkering on HackTheBox to increase my skill set. I enjoy what I do, and it’s why I switched my career from fixing Helicopters to Cyber Security. <br></br><br></br>

I’m  working on Vulnerability Management to get a better process down for patching and metric reporting. I also leverage my knowledge on HackTheBox (and other forms of education) to improve the Incident Response program and be a better blue team guy. I do so much more, but I like to think of myself as a jack of all trades, master of none. <br></br><br></br>

When I’m not tinkering on the computer, I’m improving my recipe book to be a better cook at home and work on my 1967 Ford Mustang that I am restoring. Additionally, I  taught myself front end web design. Which is how I was  able to make this (React using VS Code) Its a nice side project.
          </p>
          <a href='#contact' className='btn btn-primary'> Contact Me</a>
        </div>
      </div>
    </section>
  )
}

export default about
import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {SiHackthebox} from 'react-icons/si'
import {AiFillHeart} from 'react-icons/ai'

const footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'></a>

      <ul className='permalinks'>
      <li><a href="#">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#experience">Experience</a></li>
      <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/matthewfigueroa/" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/MathewFigueroa" target="_blank"><FaGithub /></a>
        <a href="https://app.hackthebox.com/profile/400888" target="_blank"><SiHackthebox /></a>
      </div>
      <div className="footer__copyright"><p><center>
        <small>Made with <font color = "red"><AiFillHeart /></font> by Matthew Figueroa <br />&copy; {new Date().getFullYear()} All rights reserved.<br /><br /><br /><br /><br /></small>
        </center>
        </p>
      </div>
    </footer>
  )
}

export default footer
import React, { useRef } from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import emailjs from 'emailjs-com';

const Contact = () => {
  
  /* Emailjs.com contact form start */
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_e7mrq33', 'template_10qpmzk', form.current, '1UcshZdsstaMxOkV1')

    e.target.reset()
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
/* Emailjs.com contact form end */
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
          <MdOutlineEmail />
          <h4>Email</h4>
          <h5>Matt@devhacker.io</h5>
          <a href="mailto:Matt@devhacker.io" target="_blank">Send an Email</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS*/}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='YourEmail' required />
          <textarea name="message" rows="7" placeholder='Your Message' required ></textarea>
          <button type='submit'className='btn btn-primary'>Send Message</button><br /><br /><br /><br /><br />
        </form>
      </div>
    </section>

  )
}

export default Contact
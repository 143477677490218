import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const experience = () => {
  return (
    <section id='experience'>
      <h5>The Skills I have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        {/* ========================= Beginning of CyberSecurity================*/}
        <div className="experience__frontend">
          <h3>Cybersecurity</h3>

          <div className="experience__content">
            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon' />
            <div><h4>OSINT</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>

            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon' />
            <div><h4>Penetration Testing</h4>
            <small className='text-light'>Intermediate</small>
            </div>
            </article>

            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon' />
            <div><h4>Vulnerability Management</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>

            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon'  />
            <div><h4>Security Operations</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>

            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon'  />
            <div><h4>Security Architecture</h4>
            <small className='text-light'>Intermediate</small>
            </div>
            </article>

            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon'  />
            <div><h4>Forensics</h4>
            <small className='text-light'>Intermediate</small>
            </div>
            </article>
          </div>
        </div>
        {/* ========================= End of Cyber Security================*/}

        {/* ========================= Beginning of Development================*/}
        <div className="experience__backend">
          <h3>Development</h3>
          <div className="experience__content">
            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon'  />
            <div><h4>HTML</h4>
            <small className='text-light'>Intermediate</small>
            </div>
            </article>

            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon'  />
            <div><h4>CSS</h4>
            <small className='text-light'>Intermediate</small>
            </div>
            </article>

            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon'  />
            <div><h4>React</h4>
            <small className='text-light'>Basic</small>
            </div>
            </article>

            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon'  />
            <div><h4>PowerShell</h4>
            <small className='text-light'>Intermediate</small>
            </div>
            </article>

            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon'  />
            <div><h4>TailWind</h4>
            <small className='text-light'>Intermediate</small>
            </div>
            </article>

            <article className='experience__details'> 
            <BsPatchCheckFill className='experience__details-icon'  />
            <div><h4>WordPress</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>
        {/* ========================= End of Backend================*/}
        </div>
      </div>
</div><br /><br /><br /><br /><br />
    </section>
  )
}

export default experience